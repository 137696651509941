import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LayoutNavbar from './pages/LayoutNavbar';
import Home from './pages/Home';
import Products from './pages/Products';
import { persistor, store } from './storeSlice/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ViewProduct from './pages/ViewProduct';
import Profile from './pages/Profile';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              <Route path='/' element={<LayoutNavbar />}>
                <Route index element={<Home />} />
                <Route path='peoducts' element={<Products />} />
                <Route path='viewproduct' element={<ViewProduct />} />
                <Route path='profile' element={<Profile />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
