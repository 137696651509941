import { useDispatch, useSelector } from 'react-redux';
import '../styles/ViewProduct.css'
import '../styles/styleMobile.scss'
import React, { useEffect, useState } from 'react';
import { login, logout } from '../storeSlice/contextSlice/Context';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faXmark, faImage, faCircleExclamation, faBahtSign, faSpinner, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewProduct = () => {
    const dispatch = useDispatch()
    const [viewScript, setViewScript] = useState(null);
    const [imgQrCode, setImgQrCode] = useState(null);
    const [moreImg, setMoreImg] = useState(null);
    const [userLogin, setLogin] = useState(true);
    const [checkQr, setCheckQr] = useState(true)
    const [click, setClick] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showMoreImg, setShowMoreImg] = useState(false);
    const [showSliderButtons, setShowSliderButtons] = useState(false);
    const [payment, setPayment] = useState('qrCode');
    const [IP, setIP] = useState('');
    const [IPWallet, setIPWallet] = useState('');
    const [wallet, setwallet] = useState('');
    const [time, setTime] = useState(0);
    const [idPay, setIdPay] = useState(0);
    const data = useSelector((state) => state);
    const onCloseMore = () => setShowMoreImg(false)
    const success = () => toast.success("แจ้งชำระเงินสำเร็จ")

    const handleLogin = () => {
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1148326422117621760&redirect_uri=https%3A%2F%2Ffunny-production.com&response_type=code&scope=identify%20connections';
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            const config = {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            };

            axios.get(`https://mrbinsertip.work:3003/api/callback?code=${code}`, config)
                .then((response) => {
                    const user = JSON.parse(response.data.data);
                    dispatch(login(user))
                })
                .catch((error) => {
                });
        }

        if (data.clientID === 0) {
            setLogin(false)
        }

        if (!data.accessToken) {
            dispatch(logout())
        }

        if (!data.product) {
            return
        }

        setViewScript(data.product)
        addSliderButtonListeners(data.product)

        let interval = null;

        if (time > 0) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };


    }, [data.clientID, time]);

    const handleWallet = (e) => {
        const wallet = e.target.value;
        setwallet(wallet)
    }

    const handleIP = (e) => {
        const IP = e.target.value;
        const filteredIP = IP.replace(/[^\d.]/g, '');
        setIP(filteredIP)
    }
    const handleIPWallet = (e) => {
        const IP = e.target.value;
        const filteredIP = IP.replace(/[^\d.]/g, '');
        setIPWallet(filteredIP)
    }

    const onPayProduct = () => {
        setClick(!click)
        setLoading(true)

        const API = 'https://mrbinsertip.work:3003/createQrCode'

        let price = 0
        if (!viewScript.salePrice) {
            price = viewScript.price
        } else {
            price = viewScript.salePrice
        }
        axios.post(API, {
            "clientID": data.clientID,
            "nameScript": viewScript.role,
            "amount": price
        })
            .then((response) => {
                if (response.data.data === "{}") {
                    setCheckQr(false)
                    setLoading(false)
                } else {
                    setCheckQr(true)
                    const data = JSON.parse(response.data.data);
                    const imageUrl = data.qr_image_base64;
                    const imgQrCodeBase64 = `data:image/png;base64,${imageUrl}`;
                    setImgQrCode(imgQrCodeBase64)
                    const time = data.time_out / 60

                    const totalSeconds = Math.floor(time * 60);
                    setTime(totalSeconds)
                    setIdPay(data.id_pay)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            });
    }


    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const Time = formattedMinutes + ' : ' + formattedSeconds;

    const qrCode = (click) => { setPayment(click) }
    const Wallet = (click) => { setPayment(click) }

    const closePay = () => {
        setIPWallet('')
        setIP('')
        setwallet('')
        setClick(false)
    }

    const payOrder = () => {
        if (payment === 'qrCode') {
            setLoading(true)
            const API = 'https://mrbinsertip.work:3003/verifyQrCode'
            if (!IP || Time === '00 : 00') {
                toast.warn("กรุณาโอนเงิน หรือกรอก IP ก่อนแจ้งชำระเงิน")
                setLoading(false)
                return
            } else {
                axios.post(API, {
                    "id_pay": idPay,
                    "ip": IP
                })
                    .then((response) => {
                        if (response.data.success === false) {
                            const message = response.data.message
                            setLoading(false)
                            toast.warn(message)
                        } else {
                            setClick(false)
                            success()
                            setLoading(false)
                            toast.info("ติดต่อรับสคริปต์ได้ที่ Discord")
                            setIPWallet('')
                            setIP('')
                            setwallet('')
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    });
            }
        } else if (payment === 'wallet') {

            const API = 'https://mrbinsertip.work:3003/verifyTrueMoneyWallet'
            if (!IPWallet || !wallet) {
                toast.warn("กรุณาใส่ลิ้งค์อั่งเปา หรือกรอก IP ก่อนแจ้งชำระเงิน")
                return
            } else {
                axios.post(API, {
                    "link": wallet,
                    "ip": IPWallet,
                    "clientID": data.clientID,
                    "nameScript": viewScript.role
                })
                    .then((response) => {
                        if (response.data.success === false) {
                            const message = response.data.message
                            setLoading(false)
                            toast.warn(message)
                        } else {
                            setClick(false)
                            success()
                            setLoading(false)
                            toast.info("ติดต่อรับสคริปต์ได้ที่ Discord")
                            setIPWallet('')
                            setIP('')
                            setwallet('')
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                    });
            }
        }
    }

    function addSliderButtonListeners(product) {
        setInterval(() => {
            const sliderContainer = document.querySelector('.slider');
            const sliderButtons = document.querySelectorAll('.slider-button');
            const slideAmount = 350
            if (product.img.length > 4) {
                setShowSliderButtons(true)
                sliderButtons.forEach((button) => {
                    button.addEventListener('click', () => {
                        if (button.classList.contains('slider-button-left')) {
                            const currentPosition = sliderContainer.scrollLeft;
                            const targetPosition = currentPosition - slideAmount;
                            sliderContainer.scrollTo({ left: targetPosition, behavior: 'smooth' });
                        } else if (button.classList.contains('slider-button-right')) {
                            const currentPosition = sliderContainer.scrollLeft;
                            const targetPosition = currentPosition + slideAmount;
                            sliderContainer.scrollTo({ left: targetPosition, behavior: 'smooth' });
                        }
                    });
                });
                return
            }
        }, 100);
    }


    const onMoreImg = (img) => {
        setMoreImg(img)
        setShowMoreImg(true)
    }

    return (
        <div className="from-viewproduct">
            {viewScript && (
                <div>
                    {loading && (
                        <div className='fixed text-white gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50'>
                            <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin '></FontAwesomeIcon>
                            load...
                        </div>
                    )}
                    <div className='layout-header-viewproduct cursor-default'>
                        <div className='layout-header-body-viewproduct'>
                            {/* <div className='img-header-viewproduct'>
                                <img className='img-viewproduct' src={viewScript.img} alt="" />
                            </div> */}
                            {viewScript.img.map((line, index) => (
                                <React.Fragment key={index}>
                                    {index === 0 && (
                                        <div className='img-header-viewproduct'>
                                            <img className='img-viewproduct' src={line} alt="" />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                            <div className='details-header-viewproduct'>
                                <div className='layout-name-viewproduct'>
                                    <p className='text-amber-500 font-bold'>{viewScript.label}</p>
                                    <p className='text-[1.5vw]'>{viewScript.role}</p>
                                </div>
                                <div className='layout-price-viewproduct'>
                                    {viewScript.salePrice ?
                                        <>
                                            <p className='text-red-600 text-[1.3vw] font-bold line-through'>{viewScript.price}.-</p>
                                            <p className='text-amber-500 text-[1.8vw] font-bold'>{viewScript.salePrice}.-</p>
                                        </> : <>
                                            <p className='text-amber-500 text-[1.8vw] font-bold'>{viewScript.price}.-</p>
                                        </>}
                                </div>
                                {userLogin ?
                                    <>
                                        <div className='layout-pay-viewproduct'>
                                            <button onClick={() => onPayProduct()} className=' bg-amber-500 text-[25px] font-bold p-[.8%] pl-[3%] pr-[3%] tracking-[.05em] mt-[10px] w-[100%]'>ซื้อตอนนี้!</button>
                                            <p className='text-[.9vw] mt-[10px] text-center'>หลังจากชำระเงิน สามารถรับสคริปต์ได้ที่ Discord</p>
                                        </div>
                                    </> : <>
                                        <div className='layout-pay-viewproduct'>
                                            <button onClick={() => handleLogin()} className=' bg-amber-500 text-[25px] font-bold p-[.8%] pl-[3%] pr-[3%] tracking-[.05em] mt-[10px] w-[100%]'>เข้าสู่ระบบตอนนี้!</button>
                                            <p className='text-[.9vw] mt-[10px] text-center'>หลังจากชำระเงิน สามารถรับสคริปต์ได้ที่ Discord</p>
                                        </div>
                                    </>}
                            </div>
                        </div>
                        <div className='layout-header-footer-viewproduct'>
                            <div className='slider'>
                                {viewScript.img.map((line, index) => (

                                    <img key={index} onClick={() => onMoreImg(line)} className='img-more cursor-pointer hover:scale-[1.02] transition delay-50 duration-300 ease-in-out' src={line} alt="" />

                                ))}
                            </div>
                            {showSliderButtons && (
                                <>
                                    <button className="slider-button slider-button-left">
                                        <FontAwesomeIcon icon={faChevronLeft} className='icon-chevron-left'></FontAwesomeIcon>
                                    </button>
                                    <button className="slider-button slider-button-right">
                                        <FontAwesomeIcon icon={faChevronRight} className='icon-chevron-right'></FontAwesomeIcon>
                                    </button>
                                </>
                            )}

                        </div>
                        {showMoreImg && (
                            <div onClick={() => onCloseMore()} className='fixed text-white gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50 transition delay-150 duration-300 ease-in'>
                                <img className='img-view-more transition delay-150 duration-300 ease-in' src={moreImg} alt="" />
                            </div>
                        )}
                    </div>
                    <div className='layout-body-viewproduct cursor-default'>
                        <div className='header-body-viewproduct'>
                            <p className='bg-amber-500 text-[25px] font-bold rounded-[10px] pl-[10px] pr-[10px]'>รายระเอียดสินค้า</p>
                            <label>
                                <p>Current Version: {viewScript.version}</p>
                                <p className='text-[12px]'>Updeted on {viewScript.updated}</p>
                            </label>
                        </div>
                        <div className='body-viewproduct'>
                            {viewScript.details.split('\\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {index !== 0 && (
                                        <label className='flex gap-[1vw] pt-[.5vw] items-center'>
                                            <FontAwesomeIcon className='icon-circle text-[13px] text-amber-500' icon={faCircle}></FontAwesomeIcon>
                                            {line}
                                        </label>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className='layout-footer-viewproduct'>
                        <div className='iframe-youtube'>
                            <iframe
                                src={`https://www.youtube.com/embed/${viewScript.videoId}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>

                    </div>
                    <div className='layout-pay-footer cursor-default'>
                        {userLogin ?
                            <>
                                <div className='layout-pay-viewproduct'>
                                    <button onClick={() => onPayProduct()} className=' bg-amber-500 text-[25px] font-bold p-[.8%] rounded-[10px] pl-[3%] pr-[3%] tracking-[.05em] mt-[10px] w-[100%]'>ซื้อตอนนี้!</button>
                                    <p className='text-[.9vw] mt-[10px] text-center'>หลังจากชำระเงิน สามารถรับสคริปต์ได้ที่ Discord</p>
                                </div>
                            </> : <>
                                <div className='layout-pay-viewproduct'>
                                    <button onClick={() => handleLogin()} className=' bg-amber-500 text-[25px] font-bold p-[.8%] rounded-[10px] pl-[3%] pr-[3%] tracking-[.05em] mt-[10px] w-[100%]'>เข้าสู่ระบบตอนนี้!</button>
                                    <p className='text-[.9vw] mt-[10px] text-center'>หลังจากชำระเงิน สามารถรับสคริปต์ได้ที่ Discord</p>
                                </div>
                            </>}
                    </div>
                    <div className={click ? 'pay-product active-pay-product' : 'pay-product'} >
                        <div className='header-pay flex items-center justify-between p-[10px] cursor-default'>
                            <p className='text-[15px] p-[5px] px-[15px] bg-black backdrop-blur-sm bg-white/10 rounded-[5px]'>สั่งซื้อ</p>
                            <FontAwesomeIcon icon={faXmark}
                                onClick={closePay}
                                className='icon-close-pay cursor-pointer z-10 p-[7px] rounded-[5px] hover:bg-red-400 transition ease-in-out delay-75 duration-300  backdrop-blur-sm bg-white/10'></FontAwesomeIcon>
                        </div>
                        <hr />
                        <div className='pay-details p-[20px] px-[30px] pt-[30px] cursor-default'>
                            <p className='text-[13px] text-left'>ช่องทางการชำระ</p>
                            <div className='btn-choose-pay flex justify-between items-center p-[20px] px-[40px]'>
                                <button onClick={() => qrCode('qrCode')} className={payment === 'qrCode' ? 'payment active-payment' : 'payment'}>QR Code</button>
                                <p>|</p>
                                <button onClick={() => Wallet('wallet')} className={payment === 'wallet' ? 'payment active-payment' : 'payment'}>อั่งเปา</button>
                            </div>

                            <div className='card-choose-pay bg-white backdrop-blur-sm bg-white/10 p-[20px] rounded-[15px] w-[100%] h-[11vw] relative'>
                                {payment === 'qrCode' && <>
                                    {checkQr ? (
                                        <div className='card-img-qr w-[52%] max-w-[100%] h-[100%] mr-[auto] ml-[auto] relative z-10 '>
                                            <img className='img-qr w-[100%] h-[100%] mr-[auto] ml-[auto] transition ease-in-out delay-75 duration-300 object-cover object-top' src={imgQrCode} alt="QR Code" />
                                        </div>
                                    ) : (
                                        <div className='card-img-qr w-[52%] max-w-[100%] h-[100%] mr-[auto] ml-[auto] relative z-10 border border-zinc-600 text-center'>
                                            <FontAwesomeIcon icon={faImage} className='text-[29px] text-gray-400 mt-[30%]'></FontAwesomeIcon>
                                            <p className='text-[12px]'>No Image Found</p>
                                        </div>
                                    )}
                                </>}
                                {payment === 'wallet' &&
                                    <img className='img-wallet w-[50%] mr-[auto] ml-[auto] transition ease-in-out delay-75 duration-300'
                                        src="https://www.truemoney.com/wp-content/uploads/2022/01/truemoneywallet-sendgift-hongbao-20220125-icon-2.png" alt="Img Wallet" />
                                }
                            </div>
                            {payment === 'qrCode' &&
                                <>
                                    <p className='text-name-script font-bold text-[15px] text-center mt-[10px]'>{Time}</p>
                                    <span className='flex gap-[10px] items-center p-[4px] pl-[10px] bg-white backdrop-blur-sm bg-white/20 border-[1px] border-yellow-500 rounded-[10px] mt-[10px]'>
                                        <FontAwesomeIcon icon={faCircleExclamation} className='text-yellow-500'></FontAwesomeIcon>
                                        <p className='text-[13px] pt-[1px]'>เมื่อหมดเวลา หรือไม่มี QR Code กรุณาทำการกดสั่งซื่อใหม่</p>
                                    </span>
                                    <span className='w-[100%] flex justify-center mt-[15px]'>
                                        <input
                                            type="text"
                                            name=""
                                            value={IP}
                                            onChange={handleIP}
                                            placeholder='IP Server'
                                            className='h-[30px] w-[50%] rounded-[5px] focus:outline-none px-[10px] text-black text-[15px]' />
                                    </span>
                                </>
                            }
                            {payment === 'wallet' &&
                                <>
                                    <span className='w-[100%] flex justify-center mt-[10px]'>
                                        <input
                                            type="text"
                                            name=""
                                            value={wallet}
                                            onChange={handleWallet}
                                            placeholder='ลิงค์อั่งเปา'
                                            className='h-[30px] w-[80%] rounded-[5px] focus:outline-none px-[10px] text-black text-[15px]' />
                                    </span>
                                    <span className='w-[100%] flex justify-center mt-[15px]'>
                                        <input
                                            type="text"
                                            name=""
                                            value={IPWallet}
                                            onChange={handleIPWallet}
                                            placeholder='IP Server'
                                            className='h-[30px] w-[50%] rounded-[5px] focus:outline-none px-[10px] text-black text-[15px]' />
                                    </span>
                                    <span className='flex gap-[10px] items-center p-[4px] pl-[10px] bg-white backdrop-blur-sm bg-white/20 border-[1px] border-yellow-500 rounded-[10px] mt-[10px]'>
                                        <FontAwesomeIcon icon={faCircleExclamation} className='text-yellow-500'></FontAwesomeIcon>
                                        <p className='text-[13px] pt-[1px]'>กรุณาเติมอั่งเปาตามจำนวนที่ต้องชำระเงิน</p>
                                    </span>
                                </>
                            }

                        </div>

                        <div className='pay-details-script px-[50px] pt-[10px] cursor-default'>
                            <p className='text-[13px] text-left'>script</p>
                            <div className='flex justify-between items-center'>
                                <p className='text-name-script font-bold text-[18px]'>{viewScript.label}</p>
                                {viewScript.salePrice ?
                                    <>
                                        <p className='text-[20px]'>{Number(viewScript.salePrice).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                                    </> : <>
                                        <p className='text-[20px]'>{Number(viewScript.price).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                                    </>}
                            </div>
                        </div>
                        <div className='p-[20px] px-[30px]'>
                            <button type=""
                                onClick={payOrder}
                                className="rounded-md bg-gradient-to-r from-amber-500 to-amber-700 px-2 py-1 text-xs text-white ring-1 ring-inset ring-pink-700/10 w-[100%] h-[30px] hover:bg-red-700 hover:scale-[.99] transition delay-75 duration-300 ease-in-out"
                            ><p className='text-[20px] font-bold'>แจ้งชำระเงิน</p></button>
                        </div>
                    </div>
                    <ToastContainer theme="dark" />
                </div>
            )}
        </div>
    )
}


export default ViewProduct